import { Box, Flex, Show, SimpleGrid, Text } from '@chakra-ui/react';

import { IIndexable } from '@/customtypes/common';
import { StrapiCard, StrapiSectionDetail } from '@/customtypes/global';
import BaseImage from '@/dapp-sdk-v2/components/Image';

import Section from '../Section';

interface MeetSectionProps {
  meetData?: StrapiCard[];
  sectionDetails: StrapiSectionDetail;
}

const getAboutImages: IIndexable = {
  yield: '/assets/images/meet/yield.svg',
  defi: '/assets/images/meet/defi.svg',
  distributed: '/assets/images/meet/decentralized.svg',
  secure: '/assets/images/meet/secure.svg',
  // NODE OPERATORS
  setup: '/assets/images/eth_node/setup.svg',
  tokenomics: '/assets/images/eth_node/tokenomics.svg',
  returns: '/assets/images/eth_node/returns.svg',
  fourETH: '/assets/images/eth_node/4eth.svg',
  // BNB
  secureBNB: '/assets/images/meet/secure-bnb.svg',
  defiOpportunities: '/assets/images/meet/defi-opportunities.svg',
  simple: '/assets/images/meet/simple.svg',
};

const Meet = ({ meetData = [], sectionDetails }: MeetSectionProps) => {
  return (
    <Section
      title={sectionDetails.title ?? undefined}
      subTitle={sectionDetails.sub_title ?? undefined}
      id={sectionDetails.section_id ?? undefined}
    >
      {meetData && (
        <Box mt={{ base: '40px', lg: '64px' }}>
          {meetData.length === 4 ? (
            <SimpleGrid columns={{ base: 1, lg: 4 }} gap="1.5rem">
              {meetData.map((data: StrapiCard) => (
                <Flex
                  flexDir="column"
                  align="center"
                  key={data.key}
                  p={{ base: '16px 24px', lg: '24px 16px' }}
                  minH={{ base: 'fit-content', lg: '304px' }}
                >
                  {data.img_key && (
                    <BaseImage
                      src={getAboutImages[data.img_key]}
                      width={120}
                      height={120}
                      alt={data.key || data.img_key}
                    />
                  )}

                  <Text
                    fontSize={{ base: 16, xl: 24 }}
                    fontWeight="600"
                    mt="1.5rem"
                  >
                    {data?.title}
                  </Text>
                  <Text
                    fontWeight="500"
                    fontSize={{ base: 12, xl: 14 }}
                    textAlign="center"
                    mt="4px"
                    color="textSecondary"
                    maxW={{ base: '280px', xl: '310px' }}
                  >
                    {data?.description}
                  </Text>
                </Flex>
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid columns={{ base: 1, lg: 3 }} gap="24px">
              {meetData.map((data: StrapiCard) => (
                <Flex
                  flexDir={{ base: 'row', lg: 'column' }}
                  align="center"
                  key={data.key}
                  p={{ base: '16px 24px', lg: '0 24px' }}
                  gap="1.5rem"
                >
                  {data.img_key && (
                    <>
                      <Show above="lg">
                        <BaseImage
                          src={getAboutImages[data.img_key]}
                          alt={data.key || data.img_key}
                          width={64}
                          height={64}
                        />
                      </Show>
                      <Show below="lg">
                        <BaseImage
                          src={getAboutImages[data.img_key]}
                          alt={data.key || data.img_key}
                          width={40}
                          height={40}
                        />
                      </Show>
                    </>
                  )}

                  <Box textAlign={{ base: 'left', lg: 'center' }}>
                    <Text fontSize={{ base: 16, lg: 24 }} fontWeight="600">
                      {data?.title}
                    </Text>
                    <Text
                      fontWeight="500"
                      fontSize={{ base: 12, xl: 14 }}
                      mt="4px"
                      color="textSecondary"
                      maxW={{ base: '280px', lg: '333px' }}
                    >
                      {data?.description}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </SimpleGrid>
          )}
        </Box>
      )}
    </Section>
  );
};

export default Meet;
